<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="6">
        <v-btn-toggle v-model="usersStatus" color="primary">
          <v-btn text small value="all" style="height: 50px"> All </v-btn>

          <v-btn text small value="approved" style="height: 50px">
            Approved
          </v-btn>
          <v-btn text small value="pending" style="height: 50px">
            Pending
          </v-btn>
          <v-btn text small value="declined" style="height: 50px">
            Declined
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="6" class="mt-3">
        <v-row>
          <div style="width: 200px">
            <date-filter :init-date="initDate" @dateChange="onDateChange" />
          </div>
          <v-text-field
            label="Search Payouts"
            solo
            append-icon="mdi-magnify"
            class="mr-3"
            @change="search"
            style="width: 95px; height: 47px; margin-left: 5px"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="payouts.data"
        :server-items-length="tableOptions.totalItems"
        :options.sync="tableOptions"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': tableOptions.rowsPerPageItems,
        }"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.driverId.firstName }} {{ item.driverId.surName }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          £ {{ item.amount.toFixed(2) }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            class="ma-2"
            label
            outlined
            small
            :color="getColor(item.status)"
          >
            {{ getAction(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ getReadableDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{
            item.status === "approved"
              ? getReadableDate(item.updatedAt)
              : "NA"
          }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip v-if="item.status == 'pending'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="approvePayoutAction(item)"
              >
                mdi-cash-check }}
              </v-icon>
            </template>
            <span> Approve Payout </span>
          </v-tooltip>
          <v-tooltip v-if="item.status == 'pending'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="declinePayoutAction(item)"
              >
                mdi-alert-circle }}
              </v-icon>
            </template>
            <span> Decline Payout </span>
          </v-tooltip>
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            v-if="item.status === 'pending'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="partialPayoutAction(item)"
              >
                mdi-border-color }}
              </v-icon>
            </template>
            <span> Partial Payout </span>
            <v-card>
              <v-card-title>
                <span class="text-h5">Payouts</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Amount"
                        v-model="price"
                        prefix="$"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="partialPayoutSubmit(item)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import DateFilter from "@/components/dateFilter";

import {
  getPayouts,
  approvePayout,
  declinePayout,
  partialPayout,
} from "@/services/admin";
export default {
  components: { DateFilter },
  data() {
    return {
      dialog: false,
      userFormOpen: false,
      price: 0,
      usersStatus: "all",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "_id",
        },
        {
          text: "Driver",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Amount",
          align: "start",
          sortable: false,
          value: "amount",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: "Request Date",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Approved Date",
          align: "start",
          sortable: false,
          value: "updatedAt",
        },
        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      payouts: {
        data: [],
        actions: ["approve"],
      },
      tableOptions: {
        page: 1,
        itemsPerPage: 200,
        totalItems: 0,
        rowsPerPageItems: [200, 500, 1000, 2000],
      },
      loading: true,
      searchKeyword: "",
      dateFilter: null,
      initDate: [],
    };
  },
  watch: {
    tableOptions: {
      handler() {
        this.loading = true;
        const _self = this;
        setTimeout(function () {
          _self.getData();
        }, 500);
      },
      deep: true,
    },
    usersStatus() {
      this.loading = true;
      this.getData();
    },
  },
  async mounted() {
    // const date = new Date();
    // if (!localStorage.getItem("filterDate")) {
    //   this.initDate.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    //   this.initDate.push(
    //     `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    //   );
    // } else {
    //   let datearr = localStorage.getItem("filterDate").split(",");
    //   let date1 = new Date(datearr[0]);
    //   let date2 = new Date(datearr[1]);
    //   this.initDate.push(
    //     `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}`
    //   );
    //   this.initDate.push(
    //     `${date2.getFullYear()}-${date2.getMonth() + 1}-${date2.getDate()}`
    //   );
    // }
    this.getData();
  },
  methods: {
    search(keyword) {
      this.searchKeyword = keyword;
      this.getData();
    },
    userModalClose() {
      this.getData();
    },
    onDateChange(newValue) {
      if (newValue.length < 2) return;
      localStorage.setItem("filterDate", newValue);
      let finaldate = localStorage.getItem("filterDate").split(",");
      this.dateFilter = localStorage.getItem("filterDate")
        ? [new Date(finaldate[0]), new Date(finaldate[1])]
        : newValue;
      this.getData();
    },
    async getData() {
      this.loading = true;
      const params = {
        status: this.usersStatus === "all" ? null : this.usersStatus,
        search: this.searchKeyword,
        limit: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
        date: this.searchKeyword ? null : this.dateFilter?.join(","),
      };
      getPayouts(params).then((res) => {
        console.log('================payouts====================');
        console.log(res.data);
        console.log('====================================');
        this.payouts.data = res.data.transactions;
        this.tableOptions.totalItems = res.data.count;
        this.loading = false;
      });
    },
   async approvePayoutAction(item) {
        approvePayout(item._id).then((res) => {
        alert("Payout request approved.");
          this.getData();
      }).catch(err => {
          // var res = JSON.parse(err.request.response);
          // alert(res.error.raw.message);
          alert("ERROR: "+(err?.response?.data?.error?.raw?.message ||err.request.response ||  err?.response?.data?.error || "Error in approving payout request"));
          this.getData();
      })
    },
    declinePayoutAction(item) {
      if (confirm("Are your Sure?")) {
        declinePayout(item._id).then((res) => {
          if (res) {
            this.getData();
            if (res) return alert("Payout is Decline Successfully!");
            else return alert("Try Again! Some went wrong.");
          } 
          alert("Error in declining payout request.");
        });
      }
    },
    partialPayoutAction(item) {
      this.price = item.amount;
    },
    partialPayoutSubmit(item) {
      item.amount = this.price;
      partialPayout(item._id, item).then((res) => {
        console.log(res);
        alert("Partial payout of amount £" + item.amount + " is made.");
        this.getData();
      }).catch(err => {
          // var res = JSON.parse(err.request.response);
          // alert(res.error.raw.message);
          alert("ERROR: "+(err?.response?.data?.error?.raw?.message ||err.request.response ||  err?.response?.data?.error || "Error in approving payout request"));
          this.getData();
      });
      this.dialog = false;
    },
    getReadableDate(date) {
      const d = new Date(date);
      return d.toUTCString();
    },
    getColor(status) {
      if (status === "pending") return "red";
      else if (status === "declined") return "red";
      else if(status === "PARTIAL AMOUNT PAID") return "gray";
      else if(status === "approved") return "green";
    },
    getAction(status) {
      if (status === "pending") return "Pending";
      else if (status === "declined") return "Decline";
      else if(status === "PARTIAL AMOUNT PAID") return "PARTIAL AMOUNT PAID";
      else if(status === "approved") return "Approved";
    },
  },
};
</script>
